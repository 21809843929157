import React, { Component } from "react";
import { Link } from "react-router-dom";
class Book extends Component {
  render() {
    return (
      <div className={"col-xl-3 col-lg-3 col-md-3 col-sm-3 col-5 px-2 mb-3"}>
        <div className="card h-100">
          <Link to={"/libro/" + this.props.id}>
            <div className="card-body">
              <img src={this.props.imgSrc} className="img-fluid mb-4" alt={this.props.title} />
              <p className="card-title text-center">{this.props.title}</p>
            </div>
          </Link>
        </div>
      </div>
    );
  }
}

export default Book;
