import React from 'react';
import '../App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
const Pagination = ({ postPerPage, totalPosts, paginate, current }) => {

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalPosts / postPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <nav className="d-flex justify-content-center my-3">
            {pageNumbers.length > 1 &&
                <ul className="pagination">
                    <li className="page-item">
                        <a href={() => false} className="cursor-pointer text-dark page-link" onClick={() => paginate(1)} aria-label="Previous">
                            <span aria-hidden="true"><FontAwesomeIcon icon={faAngleDoubleLeft} /></span>
                        </a>
                    </li>
                    <li className="page-item">
                        <a href={() => false} className="cursor-pointer text-dark page-link" onClick={() => paginate(current - 1 === 0 ? current : current - 1)} aria-label="Previous">
                            <span aria-hidden="true"><FontAwesomeIcon icon={faAngleLeft} /></span>
                        </a>
                    </li>{
                        pageNumbers.map((number) =>
                            <li key={number} className={"fw-semibold page-item" + (number === current ? " active" : "")} >
                                <a href={() => false} onClick={() => paginate(number)} className="cursor-pointer text-dark page-link">{number}</a>
                            </li>
                        )}
                    <li className="page-item">
                        <a href={() => false} className="cursor-pointer text-dark page-link" onClick={() => paginate(current + 1 <= pageNumbers.length ? current + 1 : current)} aria-label="Next">
                            <span aria-hidden="true"><FontAwesomeIcon icon={faAngleRight} /></span>
                        </a>
                    </li>
                    <li className="page-item">
                        <a href={() => false} className="cursor-pointer text-dark page-link" onClick={() => paginate(pageNumbers.length)} aria-label="Next">
                            <span aria-hidden="true"><FontAwesomeIcon icon={faAngleDoubleRight} /></span>
                        </a>
                    </li>
                </ul>
            }
        </nav>
    )

}
export default Pagination;

