import React, { useState, useEffect } from "react";
import { faSliders } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Book from "./Book";
import Pagination from "./Pagination";
import config from "../config/api";
import constants from "../config/constants.js";
import BreadCrumbs from "./BreadCrumbs.js";

function Books({ idioma, tipo }) {
  const [items, setItems] = useState([]);
  const [actual, setActual] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(12);
  const [froilanSeries, setFroilanSeries] = useState([]);

  //Get current posts
  const indexOfLastPosts = currentPage * postsPerPage;
  const indexOfFirstPosts = indexOfLastPosts - postsPerPage;
  const currentPosts = actual.slice(indexOfFirstPosts, indexOfLastPosts);
  //Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    const obtenerDatos = async () => {
      const data = await fetch(config.domain + "/book/type/" + tipo);
      const datas = await data.json();
      setItems(datas.books);
      setActual(datas.books);
      setFroilanSeries(datas.series);
    };
    obtenerDatos();
  }, [tipo]);

  function getData(saga, serie) {
    if (saga !== constants.SAGA_TYPE_ALL) {
      saga === 2 && !serie
        ? setActual(items.filter((temp) => temp.saga === saga))
        : setActual(
            items
              .filter((temp) =>
                !serie
                  ? temp.saga === saga
                  : temp.saga === saga && temp.serie === serie
              )
              .reverse()
          );
    } else {
      setActual(items);
    }
    setCurrentPage(1);
  }
  return (
    <main role="main" className="container-lg mt-5 min-vh-100">
      <BreadCrumbs></BreadCrumbs>
      <div className="d-flex flex-wrap">
        <div className="col-xl-3 col-lg-3 col-md-5 col-sm-10 col-10 mb-5">
          <nav className="navbar navbar-expand-lg bg-light">
            <div className="container-fluid">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navBarFilters"
                aria-controls="navBarFilters"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span>
                  <FontAwesomeIcon icon={faSliders} /> Filtros
                </span>
              </button>
              <div className="collapse navbar-collapse" id="navBarFilters">
                <div className="accordion accordion-flush col-12" id="accordionFlushExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id={"flush-heading" + constants.SAGA_TYPE_ALL}>
                      <button className="accordion-button"
                        type="button" data-bs-toggle="collapse" data-bs-target={"#flush-collapse" + constants.SAGA_TYPE_ALL}
                        aria-expanded="false" aria-controls={"flush-collapse" + constants.SAGA_TYPE_ALL} onClick={() => getData(constants.SAGA_TYPE_ALL)}>Todos</button>
                    </h2>
                    <div id={"flush-collapse" + constants.SAGA_TYPE_ALL} className="accordion-collapse collapse" 
                      aria-labelledby={"flush-heading" + constants.SAGA_TYPE_ALL} data-bs-parent="#accordionFlushExample">
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id={"flush-heading" + constants.SAGA_TYPE_ELEMENTOS}>
                      <button className="accordion-button collapsed"
                        type="button" data-bs-toggle="collapse" data-bs-target={"#flush-collapse" + constants.SAGA_TYPE_ELEMENTOS}
                        aria-expanded="false" aria-controls={"flush-collapse" + constants.SAGA_TYPE_ELEMENTOS} onClick={() => getData(constants.SAGA_TYPE_ELEMENTOS)}>Saga Elementos</button>
                    </h2>
                    <div id={"flush-collapse" + constants.SAGA_TYPE_ELEMENTOS} className="accordion-collapse collapse" 
                      aria-labelledby={"flush-heading" + constants.SAGA_TYPE_ELEMENTOS} data-bs-parent="#accordionFlushExample">
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id={"flush-heading" + constants.SAGA_TYPE_FROILAN}>
                      <button className="accordion-button options collapsed"
                        type="button" data-bs-toggle="collapse" data-bs-target={"#flush-collapse" + constants.SAGA_TYPE_FROILAN}
                        aria-expanded="false" aria-controls={"flush-collapse" + constants.SAGA_TYPE_FROILAN} onClick={() => getData(constants.SAGA_TYPE_FROILAN)}>Las Aventuras de Froilán</button>
                    </h2>
                    <div id={"flush-collapse" + constants.SAGA_TYPE_FROILAN} className="accordion-collapse collapse" 
                      aria-labelledby={"flush-heading" + constants.SAGA_TYPE_FROILAN} data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                        <div className="accordion accordion-flush col-12" id="accordionFlushFroilan">
                        {froilanSeries.map((item, index) => 
                          <div className="accordion-item">
                            <h2 className="accordion-header" id={"flush-heading" + constants.SAGA_TYPE_FROILAN + item}>
                              <button className="accordion-button collapsed"
                                type="button" data-bs-toggle="collapse" data-bs-target={"#flush-collapse" + constants.SAGA_TYPE_FROILAN + item}
                                aria-expanded="false" aria-controls={"flush-collapse" + constants.SAGA_TYPE_FROILAN + item} onClick={() => getData(constants.SAGA_TYPE_FROILAN, item)}>{"Serie " + item}</button>
                            </h2>
                            <div id={"flush-collapse" + constants.SAGA_TYPE_FROILAN + item} className="accordion-collapse collapse" 
                              aria-labelledby={"flush-heading" + constants.SAGA_TYPE_FROILAN + item} data-bs-parent="#accordionFlushFroilan">
                            </div>
                          </div>
                          )}
                        </div>
                        </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id={"flush-heading" + constants.SAGA_TYPE_LYNETTE}>
                      <button className="accordion-button collapsed"
                        type="button" data-bs-toggle="collapse" data-bs-target={"#flush-collapse" + constants.SAGA_TYPE_LYNETTE}
                        aria-expanded="false" aria-controls={"flush-collapse" + constants.SAGA_TYPE_LYNETTE} onClick={() => getData(constants.SAGA_TYPE_LYNETTE)}>El Mundo de Lynette</button>
                    </h2>
                    <div id={"flush-collapse" + constants.SAGA_TYPE_LYNETTE} className="accordion-collapse collapse" 
                      aria-labelledby={"flush-heading" + constants.SAGA_TYPE_LYNETTE} data-bs-parent="#accordionFlushExample">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-10 col-10 mx-auto">
          <div className="col-12 d-flex flex-wrap justify-content-around">
          {currentPosts.map((item, index) => (
            <Book
              title={item.title}
              imgSrc={
                config.domain_img + "/storage/img/libros/" + item.image_front
              }
              id={item.id}
              key={"book-"+index}
            />
          ))}
          </div>
          <div className="col-12">
          <Pagination
            postPerPage={postsPerPage}
            totalPosts={actual.length}
            paginate={paginate}
            current={currentPage}
          />
        </div>
        </div>
      </div>
    </main>
  );
}

export default Books;
