import { faArrowLeft, faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import React from "react";

const BreadCrumbs = ({ idioma, title, id }) => {
  return (
    <div>
      <div className="mt4" aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item" aria-current="page">
            <Link to="/">
              <FontAwesomeIcon icon={faHome} />
            </Link>
          </li>
          <li className={"breadcrumb-item" + (!title ? " active" : "")} aria-current="page">
            <Link to="/libros">Libros</Link>
          </li>
          {title && (
            <li className="breadcrumb-item active" aria-current="page">
              <Link to={"/libros/" + id}>{title}</Link>
            </li>
          )}
        </ol>
      </div>
      <div className="row my-3">
        <div className="col-12 text-start">
          <Link className="btn btn-default" to={title ? "/libros" : "/"}>
            {" "}
            <FontAwesomeIcon icon={faArrowLeft} /> Regresar
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BreadCrumbs;
