import React, { useEffect, useState } from "react";
import config from "../config/api";
import { useParams } from "react-router-dom";
import BreadCrumbs from "./BreadCrumbs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAmazon, faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const BookShow = ({ idioma }) => {
  const [book, setBook] = useState({});
  const { id } = useParams();

  useEffect(() => {
    const obtenerDatos = async () => {
      const data = await fetch(config.domain + "/book/" + id);
      const datas = await data.text();
      setBook(JSON.parse(datas));
    };
    obtenerDatos();
  }, []);

  return (
    <main role="main" className="container-lg mt-5 min-vh-100">
      <BreadCrumbs title={book.title} id={book.id}></BreadCrumbs>
      <div className="d-flex justify-content-evenly flex-wrap">
        <div className="col-xl-4 col-lg-3 col-md-4 col-sm-8 col-7">
          <div
            id="carouselExample"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src={
                    config.domain_img +
                    "/storage/img/libros/" +
                    (book.image_catalogue
                      ? book.image_catalogue
                      : book.image_front)
                  }
                  className="d-block w-100"
                  alt="..."
                />
              </div>
              {book.images?.map((item, index) => (
                <div className="carousel-item" key={"item-" + index}>
                  <img
                    src={
                      config.domain_img + "/storage/img/libros/" + item.image
                    }
                    className="d-block w-100"
                    alt="..."
                  />
                </div>
              ))}
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExample"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExample"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
            <div className="carousel-indicators position-static d-flex flex-wrap mx-1">
              <button
                type="button"
                data-bs-target="#carouselExample"
                data-bs-slide-to="0"
                className="active w-25 h-auto carousel-indicator-item"
                aria-current="true"
                aria-label="Slide 1"
              >
                <img
                  src={
                    config.domain_img +
                    "/storage/img/libros/" +
                    (book.image_catalogue
                      ? book.image_catalogue
                      : book.image_front)
                  }
                  className="img-fluid"
                  alt="..."
                />
              </button>
              {book.images?.map((item, index) => (
                <button
                  type="button"
                  data-bs-target="#carouselExample"
                  data-bs-slide-to={index + 1}
                  aria-label="Slide 2"
                  key={"indicator-" + index}
                  className="w-25 h-auto carousel-indicator-item"
                >
                  <img
                    src={
                      config.domain_img + "/storage/img/libros/" + item.image
                    }
                    className="img-fluid h-100"
                    alt="..."
                  />
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-10 col-10">
          <h5>{book.title}</h5>
          <p>{book.info ?? "Cargando..."}</p>
          {book.cost && (
            <div className="dropdown">
              <a href={book.whatsapp} className="btn btn-primary me-2 mb-2">
                <span className="me-1">
                  <FontAwesomeIcon icon={faWhatsapp} />
                </span>
                Comprar por WhatsApp
              </a>
              <button
                className="btn btn-primary dropdown-toggle mb-2"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span className="me-1">
                  <FontAwesomeIcon icon={faAmazon} />
                </span>
                Comprar por Amazon
              </button>
              <ul className="dropdown-menu">
                {book.link_amazon && (
                  <li>
                    <a className="dropdown-item" href={book.link_amazon} target="_blank">
                      Amazon México
                    </a>
                  </li>
                )}
                {book.link_amazon_us && (
                  <li>
                    <a className="dropdown-item" href={book.link_amazon_us} target="_blank">
                      Amazon Global
                    </a>
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>
      </div>
    </main>
  );
};

export default BookShow;
