import React, { useState, useEffect } from 'react';
import '../App.css';
import Home from './Home';
import config from '../config/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Link, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Books from './Books';
import BookShow from './BookShow';
import constants from '../config/constants';

const Header = () => {
    const [idioma, setIdioma] = useState('Español');
    const [logo, setLogo] = useState("")

        useEffect(() => {
          const obtenerDatos = async () => {
              const data = await fetch(config.domain + '/setting/logo')
              const datas = await data.text()
              setLogo(datas)
          }
          obtenerDatos()
      }, [])
    return (
        <header className="mb-7">
        <Router>
        <nav className="navbar navbar-expand-lg navbar-dark bg-info fixed-top pe-5 ps-5">
        <div className="container-fluid">
            <Link to="/" className="navbar-brand">
                <img src={config.domain_img + '/storage/img/' + logo} height="30" alt="" loading="lazy" />
            </Link>
            <button className="navbar-toggler border-white color-white" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
                aria-label="Toggle navigation">
                <FontAwesomeIcon icon={faBars} />
            </button>
            <div className="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
                <ul className="navbar-nav text-center align-items-end">
                    <li className="nav-item active">
                        <Link to="/" className="nav-link text-light">{idioma === 'Español' ? 'Inicio' : 'Home'}</Link>
                    </li>
                    <li className="nav-item active">
                        <Link to="/libros" className="nav-link text-light">{idioma === 'Español' ? 'Libros' : 'Books'}</Link>
                    </li>
                </ul>
            </div>
            </div>
        </nav>
        <Routes>
            <Route path="/" element={<Home  idioma={idioma}/>} />
            <Route path="/libros" element={<Books  idioma={idioma} tipo={constants.BOOKS_TYPE_BOOK}/>} />
            <Route path="/libro/:id" element={<BookShow/>} />
        </Routes>
        </Router>
    </header>

    );
}
export default Header;