import React from "react";
import Slider from "./Slider.js";
import LastBooks from "./LastBooks.js";
import Notices from "./Notices.js";

const Home = ({ idioma }) => {
  return (
    <main role="main" className="container-lg min-vh-100">
      <Slider />
      <Notices />
      <LastBooks idioma={idioma}/>
    </main>
  );
};

export default Home;
