import React, { useState, useEffect }  from 'react';
import '../App.css';
import config from '../config/api';

function Notices () {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const obtenerDatos = async () => {
      const data = await fetch(config.domain + "/slider/0");
      const datas = await data.json();
      setItems(datas);
    };
    obtenerDatos();
  }, []);

  return (
    <section>
      <div className="d-flex flex-wrap my-4 justify-content-around">
        {items.map((item, index) => (
          <div className={"col-xl-3 col-lg-3 col-md-3 col-sm-3 col-5 border rounded mb-2"}
            key={"notice" + index}>
              <a href={item.link}>
                <img src={config.domain_img + "/storage/img/slider/" + item.image}
                  className="d-block w-100"
                  alt="..."
                />
              </a>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Notices;