import React, { useState, useEffect }  from 'react';
import '../App.css';
import config from '../config/api';

function Slider () {
        
    const [items, setItems] = useState([]);

    useEffect(() => {
      const obtenerDatos = async () => {
          const data = await fetch(config.domain + '/slider/1')
          const datas = await data.json()
          setItems(datas);
      }
      obtenerDatos();
  }, [])

    return (
        <div id="carouselExampleIndicators" className="carousel carousel-dark slide mt4" data-bs-ride="carousel">
          <div className="carousel-inner">
          {
                items.map((item, index) =>
                <div className={"carousel-item" + (index === 0 ? ' active' : ' ')} key={'slide' + index}>
                  <a href={item.link}>
                    <img src={config.domain_img + '/storage/img/slider/' + item.image} className="d-block w-100" alt="..."/>
                  </a>
                </div>
                )
            }
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
    );
}

export default Slider;