import React, {useEffect, useState} from 'react';
import config from '../config/api';
import Book from './Book';
import { Link } from 'react-router-dom';

const LastBooks = ({idioma}) => {

    const [items, setItems] = useState([])
    useEffect(()=>{
        const obtenerDatos = async () => {
            const data = await fetch(config.domain + '/book/filter/lastbooks')
            const datas = await data.json()
            setItems(datas)
        }
        obtenerDatos()
    },[])
    return (
      <section>
        <div className="d-flex justify-content-between mt-4 mb-2">
          <h2 className="text-center">
            {idioma === "Español" ? "Destacados" : "Best Sellers"}
          </h2>
          <Link to="/libros" className="btn btn-primary">Ver todos</Link>
        </div>
        <div className="w-75 m-auto">
          <div className="d-flex flex-wrap justify-content-between">
            {items.map((item, index) => (
              <Book
                imgSrc={
                  config.domain_img + "/storage/img/libros/" + item.image_front
                }
                title={item.title}
                id={item.id}
                key={index}
              />
            ))}
          </div>
        </div>
      </section>
    );
}
export default LastBooks;
