module.exports = Object.freeze({
  SAGA_TYPE_ELEMENTOS: 1,
  SAGA_TYPE_FROILAN: 2,
  SAGA_TYPE_LYNETTE: 3,
  SAGA_TYPE_ALL: 4,
  GALLERY_TYPE_WORLD: 1,
  GALLERY_TYPE_EVENTS: 2,
  GALLERY_TYPE_MEDIAS: 3,
  BOOKS_TYPE_BOOK: 1,
  BOOKS_TYPE_STORE: 2,
});